<!--  -->
<template>
    <div class='game-com-result'>
        <confirm-pro ref="confirm">
            <div class="c-result-message" slot="message" v-if="titleIcon">
                <div class="c-result-title flex-just-center" :class="[titleIcon]" slot="title" v-if="titleIcon">
                    <div class="title-body"></div>
                </div>

                <div class="c-gift-box position-align-center-margin" v-if="gift">
                    <div class="gift-item icon"></div>
                    <div class="gift-item text">
                        <span class="text-normal-c bold">+{{gift}}</span>
                    </div>
                </div>
                <div class="c-capture-state flex-center">
                    <div class="state-item black flex-center">
                        <stone color="black" class="stone-black"></stone>
                        <div class="cap-text">
                            <span class="text-normal-aa">{{TEXT.components.t6}}</span>
                            <span class="text-normal-aa">{{blackRemove}}</span>
                            <span class="text-normal-aa">{{TEXT.components.t7}}</span>
                        </div>
                    </div>
                    <div class="state-item white flex-center">
                        <stone color="white" class="stone-black"></stone>
                        <div class="cap-text">
                            <span class="text-normal-aa">{{TEXT.components.t6}}</span>
                            <span class="text-normal-aa">{{whiteRemove}}</span>
                            <span class="text-normal-aa">{{TEXT.components.t7}}</span>
                        </div>
                    </div>
                </div>
                <div class="c-capture-btns">
                    <div class="btns-item flex" v-for="v in buttons" :key="v.label">
                        <confirm-play :type="v.type" @click="actionHandl(v)">
                            <span class="text-normal-ab">{{v.title}}</span>
                        </confirm-play>
                    </div>
                </div>

                <!-- <div class="con-item a">
                    <stone color="black" class="stone-black"></stone>
                    <span class="text-normal light-dark-color">{{TEXT.components.t6}}{{blackRemove}}{{TEXT.components.t7}}</span>
                </div>
                <div class="con-item b">
                    <stone color="white" class="stone-white"></stone>
                    <span class="text-normal light-dark-color">{{TEXT.components.t6}}{{whiteRemove}}{{TEXT.components.t7}}</span>
                </div> -->
            </div>
        </confirm-pro>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            blackRemove: Number,
            whiteRemove: Number,
            result: String,
            userWin: Boolean,
            gameType: {
                type: String,
                default: 'capture'
            },
            boardSize: {
                type: Number,
                default: 19
            },
            saveGameData: {
                type: Object,
                default(){
                    return {}
                }
            }
        },
        data() {
            return {
                buttons: [
                    {title: '再战一局', type: 'success', label: 0},
                    {title: '查看对局', type: 'normal', label: 2},
                    {title: '退出', type: 'text', label: 1},
                ],
                response: null
            };
        },
        methods: {
            async open(){
                this.response = null
                return new Promise(success => {
                    this.$refs['confirm'].open({
                        confirmType: 'c8',
                        showClose: false
                    })
                    this.response = success
                })
            },
            close(){
                this.$refs['confirm'].close()
            },
            actionHandl(val){
                let {label} = val || {}
                if(this.response) this.response(label)
                this.close()
            }
        },
        created() {

        },
        components: {
        },
        computed: {
            gift(){
                return this.saveGameData.gift || null
            },
            title(){
                return this.titleIcon ? null : this.TEXT.components.t14
            },
            message(){
                if(this.titleIcon) return null
                let message = ''
                let {result, gameType, boardSize, userWin} = this
                if(result == 'nr') message = this.TEXT.components[`${gameType}_${boardSize}_nr`]
                else if(result == 'nt'){
                    message = this.TEXT.components[userWin ? 'nt_win' : 'nt_lose']
                }
                return message
            },
            titleIcon(){
                if(this.isDraw) return 'draw'
                if(!this.normalResult) return null
                let data = this.userWin ? 'win' : 'lose'
                return data
            },
            isDraw(){
                return this.result == 'nr'
            },
            normalResult(){
                return this.result == 'normal' || this.result == 'nt'
            },
            pattern(){
                return this.normalResult ? 'b' : 'a'
            },
        },
        watch: {},
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .game-com-result{
        .confirm-result-box{
            padding: 10px;
            text-align: center;
            .result-content{
                padding: 20px;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                .con-item+ .con-item{
                    margin-left: 20px;
                }
                .con-item{
                    display: flex;
                    .stone-white{
                        margin-left: 5px;
                    }
                    .stone-black{
                        margin-right: 5px;
                    }
                }
            }
        }
        .com-dia-button.cancel{
            // border-radius: 8px;
        }
        .com-confirm{
            .gotu-confirm{
                .content{
                    margin-top: 1px;
                }
                .pattern-b{
                    .foot {
                        margin-top: 17px;
                        .com-dia-button.cancel{
                            border-radius: 8px;
                        }
                    }
                }
            }
        }
        .c-result-title{
            margin-top: 31px;
            margin-bottom: 27px;
            height: 88px;
            width: 100%;
        }
        // .c-result-title.draw::before{
        //     content: '和棋';
        //     color: #656060;
        //     font-size: 20px;
        //     font-weight: bold;
        //     display: flex;
        //     align-items: center;
        // }
        .c-result-title.draw{
            padding-top: 16px;
            margin-bottom: 14px;
            .title-body{
                width: 258px;
                height: 100%;
                .background-img-max(url('@{assetsUrlV2_0}/game/g36_draw.png'));
            }
        }
        .c-result-title.win{
            .title-body{
                width: 258px;
                height: 100%;
                .background-img-max(url('@{assetsUrlV2_0}/game/g36_win.png'));
            }

        }
        .c-result-title.lose{
            padding-top: 29px;
            .title-body{
                width: 256px;
                height: 34px;
                .background-img-max(url('@{assetsUrlV2_0}/game/g36_lose.png'));
            }
        }
        .c-result-message{
            width: 100%;
            // display: flex;
            // justify-content: center;
            position: relative;
            .con-item{
                display: flex;
                align-items: center;
                .com-stone{
                    margin-right: 4px;
                }
            }
            .con-item+ .con-item{
                margin-left: 40px;
            }
        }

        .c-gift-box{
            padding-right: 12px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            top: 112px;
            .background-img-max(url('@{assetsUrlV2_0}/game/g37.png'));
            width: 66px;
            height: 22px;
            .gift-item.text{
            }
            .gift-item.icon{
                position: absolute;
                width: 29px;
                height: 29px;
                bottom: 0px;
                left: 0px;
                .background-img-max(url('@{assetsUrlV2_0}/game/g38.png'));
            }
        }
        .c-capture-state{
            display: flex;
            align-items: center;
            justify-content: center;
            
            .state-item+ .state-item{
                margin-left: 19px;
            }
            .state-item{
                color: #656060;
                .com-stone{
                    width: 15px;
                    height: 15px;
                    .stone-body{
                        width: 100%;
                        height: 100%;
                    }
                } 
                .cap-text{
                    margin-left: 6px;
                    width: 47px;
                    height: 12px;
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
        .c-capture-btns{
            margin-top: 15px;
            .btns-item+ .btns-item{
                margin-top: 13px;
            }
            .com-confirm-button{
                width: 131px;
                height: 39px;
            }
            .com-confirm-button.text{
                height: 19px;
                width: auto;
            }
        }
    }
</style>