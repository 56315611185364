<!--  -->
<template>
    <div class='page-game-capture' ref="gameAction">
        <component-bcg url="exercise/bcg.png"></component-bcg>
        <div class="ex-bcg-box">
            <div class="stone-item black"></div>
            <div class="stone-item white"></div>
        </div>
        <div class="capture-body">
            <div class="cap-item board game-board-box" ref="boardBox">
                <div class="board-body">
                    <board ref="board" :boardSize.sync="boardSize" @emitEvent="emitEvent" :minNum.sync="boardMinNum"></board>
                </div>
            </div>
             <!-- :class="{'filter-blur': judgeMaskState}" -->
            <div class="cap-item options game-options-box">
                <div class="options-body">
                    <div class="opt-item title text-center">
                        <span class="text-normal-ab bold">{{optTitle}}</span>
                    </div>
                    <div class="opt-item rule text-center flex-center">
                        <span class="text-mini-a light-text-color flex-center bold">{{gameRule}}</span>
                    </div>
                    <div class="opt-item remove-box flex-center">
                        <div class="re-item flex-center">
                            <span class="text-normal bold">{{leftRemove}}</span>
                        </div>
                        <div class="re-item title flex-center">
                            <span class="text-mini-a bold">{{removeTitle}}</span>
                        </div>
                        <div class="re-item flex-center">
                            <span class="text-normal bold">{{rightRemove}}</span>
                        </div>
                    </div>
                    <div class="opt-item g-info">
                        <div class="info-item content">
                            <div class="con-item left flex-just-center">
                                <div class="left-bcg bcg-item"></div>
                                <div class="stone" :class="{'black': userColor == 1, 'white': userColor == -1, 'active': !gameOver && userColor == now}"></div>
                                <avatarPet type="concise" pageName="user" :scaleNum="2" :gif="false" :petTake="false"></avatarPet>
                                <div class="name-board flex-center">
                                    <span class="text-mini-c bold">{{leftInfo.nickname}}</span>
                                </div>
                            </div>
                            <div class="con-item right flex-just-center">
                                <div class="right-bcg bcg-item"></div>
                                <div class="stone" :class="{'white': userColor == 1, 'black': userColor == -1, 'active': !gameOver && userColor != now}"></div>
                                <AIAvatar></AIAvatar>
                                <div class="name-board flex-center">
                                    <span class="text-mini-c bold">{{rightInfo.nickname}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="opt-item time-box">
                        <div class="time-item a">
                            <!-- <mainTime :time="mainTime" ref="leftMainTime" @timeUpdate="timeUpdate($event, 'black')" ></mainTime> -->
                            <gameTime ref="leftTime" 
                            :mainTime.sync="mainTime" 
                            :countdownTime.sync="countdownTime" 
                            :countdownNum.sync="countdownNum" 
                            @change="timeChange('left', $event)"
                            @timeout="timeout('left')"></gameTime>
                        </div>
                        <div class="time-item b">
                            <!-- <mainTime :time="mainTime" ref="rightMainTime" @timeUpdate="timeUpdate($event, 'white')"></mainTime> -->
                            <gameTime ref="rightTime" 
                            :mainTime.sync="mainTime" 
                            :countdownTime.sync="countdownTime" 
                            :countdownNum.sync="countdownNum" 
                            @change="timeChange('left', $event)"
                            @timeout="timeout('left')"></gameTime>
                        </div>
                    </div>
                    <div class="opt-item game-fun">
                        <div class="fun-item l">
                            <stoneLabelSwitch :value="stoneLabelValue" @change="changeLabel"></stoneLabelSwitch>
                        </div>
                        <div class="fun-item normal c scale-button flex-center"
                         @click="funHand(v)" v-for="(v, i) in funListCul" :key="i"
                        
                        :class="[v.class]"
                        >
                            <div class="normal-btn-icon" :class="v.icon"></div>
                            <div class="normal-btn-text">
                                <span class="text-mini-a bold flex-center">{{v.name}}</span>
                            </div>
                        </div>
                        <div class="fun-item normal b flex-center"
                        @click="resign" 
                        :class="{'r btm': isPlay, 'disabled': resignDisabled, 'scale-button': !resignDisabled}">
                            <div class="normal-btn-icon resign"></div>
                            <div class="normal-btn-text">
                                <span class="text-mini-a bold flex-center">认输</span>
                            </div>
                        </div>
                    </div>
                    <div class="opt-item game-fun-foot flex-center max-width">
                        <confirmPlay @click="confirmPlay" :disabled="confirmDisabled">
                            <span class="text-normal-a">{{pageText.t6}}</span>
                        </confirmPlay>
                    </div>
                </div>
                <optionsMask ref="optionsMask" v-if="judgeMaskState">
                    <div class="game-judge-result max-size flex-center">
                        <div class="re-body relative">
                            <div class="re-b-item a">
                                <stone :color="winColor" v-if="winColor" shadow="light"></stone>
                                <div class="result-shadow" v-if="winColor">
                                    <div class="shadow-two">
                                        <result class="re-b-text b" :result="playResultData"></result>
                                    </div>
                                </div>
                                <div class="re-b-text text-normal-aa text-center bold flex-center" v-else>{{pageText['t20']}}</div>
                            </div>
                            <div class="game-play-gift flex-center" v-if="gift">
                                <div class="gift-body">
                                    <div class="icon"></div>
                                    <div class="text">
                                        <span class="text-normal-d bold">+{{gift}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="re-b-item b">
                                <div class="b-item a">
                                    <confirmPlay type="trans" @click="endGame" :showShadow="false">
                                        <span class="text-normal-ab">结束对局</span>
                                    </confirmPlay>
                                </div>
                                <div class="b-item">
                                    <confirmPlay type="normal" @click="gameContinue" :showShadow="false">
                                        <span class="text-normal-ab">{{continueText}}</span>
                                    </confirmPlay>
                                </div>
                            </div>
                        </div>
                    </div>
                </optionsMask>
            </div>
        </div>
        <confirm-pro ref="confirm">
            <div class="cap-select-color">
                <div class="color-item cursor-pointer" v-for="v in colorSelect" :key="v.color" @click="colorSet(v.color)">
                    <div class="icon-box">
                        <img :src="`${PARAMS.assetsUrlV2_0}/${v.icon}`" class="color-icon">
                    </div>
                    <span class="text-normal light-dark-color">{{v.title}}</span>
                </div>
            </div>
        </confirm-pro>
        <resultToast ref="result" 
        :blackRemove="blackRemove" 
        :whiteRemove="whiteRemove" 
        :result="resultData" 
        :userWin.sync="userWin"
        :gameType="gameType"
        :boardSize="boardSize"
        :saveGameData.sync="saveGameData"
        ></resultToast>
        <!-- <resultToast ref="result" 
        :blackRemove="5" 
        :whiteRemove="1" 
        :result="'normal'" 
        :userWin="true"
        :gameType="gameType"
        :boardSize="boardSize"
        ></resultToast> -->
        <gameColorNoticePro ref="gameColorNoticePro" :color="userColor == 1 ? 'black' : 'white'" :appStyle.sync="appStyle"></gameColorNoticePro>
        <!-- <div class="fun-test">
            <dia-button @click="test">
                <span>测试</span>
            </dia-button>
        </div> -->
    </div>
</template>

<script>
    import board from '@/components/board.vue'
    // import gameUserGroup from '../components/gameUserGroup.vue'
    import apiClass from '@/API/page/game'
    import appClass from '@/API/page/app'
    import resultToast from '../components/result.vue'
    // import gameColorNotice from '../components/gameColorNotice.vue'
    import gameColorNoticePro from '../components/gameColorNoticePro.vue'
    import optionsMask from '@/components/optionsMask.vue'
    import gameTime from '@/components/game/gameTime.vue'

    export default {
        name: '',
        props: {
            appStyle: Object
        },
        data() {
            return {
                appApi: new appClass(),
                funList: [
                    {icon: 'judge', fun: 'gameJudge', class: 'judge', name: '数子'},
                    {icon: 'pass', fun: 'passClick', class: 'btm pass', name: '停一手'},
                ],
                api: new apiClass(),
                appData: new this.appDataClass({key: 'game-action'}),
                routeData: this.$route.params.routeData,
                board: null,
                // mainTime: 15 * 60,
                colorSelect: [
                    {title: this.pageText['t9'], color: 0, icon: 'game/g17.png'},
                    {title: this.pageText['t7'], color: 1, icon: 'game/g19.png'},
                    {title: this.pageText['t8'], color: -1, icon: 'game/g18.png'},
                ],
                now: 1,
                position: 0,
                step: 0,
                removeStone: null,
                AIList: [],
                pageLoaded: false,
                boardStoneLabel: 0,
                gamePlayCoor: {},
                userResign: false,
                AIResign: false,
                blackMainTimeVal: null,
                whiteMainTimeVal: null,
                leftTimeData: {},
                rightTimeData: {},
                colorRes: null,
                boardState: false,
                _board: null,
                judge: {
                    active: false,
                    data: {},
                },
                willBack: false,
                pageDestroy: false,
                colorNoticeOpen: false,
                colorNoticeTime: 2000,
                judgeMaskState: false,
                saveGameData: {},
                refuseResignMove: null,
                createTime: null
            };
        },
        methods: {
            async pageInit(){
                await this.appApi.userLevel()
                await this.gameListInit()
            },
            emitEvent(opt){
                this._common.emitEvent.call(this, opt)
            },
            async boardLoaded(board){
                this._board = this.$refs['board']
                this.boardState = true
                board.board.addEventListener('mouseleave', this.removeOutline)
                // board.board.addEventListener("click",this.playByclick);
                await this.pageInit()
                this.gameReload()
            },
            async gameReload(){
                this.pageLoaded = false
                await this._common.nextTick.call(this)
                this.timeInit()
                this.clearBoard()
                this.localDataInitCheck()
                let {userColor} = this
                await this.colorCheck()
                this.pageLoaded = true
                this.createTime = this._common.getTimeNow()
                this.AIPlay()
                let type = null
                if(userColor !== undefined) type = 'continue'
                this.timeStartCon(type)


            },
            removeOutline(){},
            stoneOver(x, y){},
            playByclickEmit(opt){
                let {x, y} = opt || {}
                delete opt.x
                delete opt.y
                this.playByclick(x, y, {...opt})
            },
            playByclick(x, y, opt){
                if(this.userPlayDisabled) return
                this._board.playByGame(x, y, opt)
            },
            async colorCheck(){
                let {userColor, color} = this
                if(userColor !== undefined) return
                if(color === undefined) color = await this.checkSelectOpen()
                if(color == 0) color = this._common.randomColor()
                userColor = color
                await this.refreshPage({items: {userColor}})
                await this.colorNotice()
            },
            async checkSelectOpen(){
                return new Promise(success => {
                    this.$refs['confirm'].open({
                        confirmType: 'c5',
                        showClose: false
                    })
                    this.colorRes = success
                })
            },
            async colorSet(color){
                color = Number(color)
                if(isNaN(color)){
                    if(this.colorRes) this.colorRes(0)
                    return
                }
                await this.refreshPage({items: {color}})
                this.$refs['confirm'].close()
                if(this.colorRes) this.colorRes(color)
                return true
            },
            async colorNotice(){
                let {userColor} = this
                if(!userColor) return
                await this.$refs['gameColorNoticePro'].open()
                // this.loadTest()
                // await this._common.toastOpen({
                //     message: this.pageText[`color_notice_${userColor}`],
                //     getContainer: '.cap-item.board .board-body',
                //     className: 'game-color-notice',
                //     duration: 2000,
                // })

                
            },
            refreshPage(opt){
                opt = opt || {}
                let {items, name, type} = opt
                type = type || 'replace'
                return this._common.refreshPage.call(this, {items, name, type})
            },
            setParams(items){
                return this._common.setParams.call(this, items)
            },
            refreshPageAction(opt){
                opt = opt || {}
                let {name, params, type} = opt
                name = name || 'game-action'
                type = type || 'replace'
                return this._common.refreshPageAction.call(this, {name, params, type})
            },
            clearBoard(){
                this._board.clearBoard()
            },
            update(opt){
                this._common.update.call(this, opt)
            },
            async gameListInit(){
                // let res = await this.api.getAIList()
                let res = await this.api.getLevelList()
                if(res) this.AIList = res
            },
            positionChange(){
                if(!this.pageLoaded) return
                if(this.isAIPlay) this.AIPlay()
                this.timeStartCon()
                this.localBoardSave()
            },
            async AIPlay(){
                if(this.AIPlayDisabled) return
                let level = this.AILevel
                let {boardSize, komi, computerResign, rule, style, apiName, AITime} = this
                let moves = this._board.getSituation()
                let judgeRes = await this.judgeChcek()
                if(!judgeRes) return
                let res = await this.api.genmove({level, boardSize, moves, komi, resign: computerResign, rule, style, apiName, AITime})
                if(!res){
                    return this.AIPlayResend()
                }
                return this.genmoveHand(res)
            },
            genmoveHand(res){
                if(this.AIPlayDisabled) return
                res = res || {}
                if(!this._common.isObject(res)) res = {coord: res}
                let {coord} = res
                // if(this._common.AIResign(coord)) return this.resignAction('AI')
                if(this._common.AIResign(coord)) return this.AIResignControl()
                if(this._common.AIPass(coord)){
                    let message = `${this.PARAMS.AIName_gaotu}停一手`
                    this._common.toastOpen({
                        message
                    })
                    return this.passAction()
                }
                if(!this._board.isInBoard(coord)){
                    return this.AIPlayResend()
                }
                this._board.playAction(coord, undefined, {AIPlay: true})
            },
            async AIPlayResend(){
                if(this.AIPlayDisabled) return
                await this._common.settimeout(this.PARAMS.genmoveResendTime)
                return this.AIPlay()
            },
            AIPlayWatchOpen(){

            },
            AIPlayWatchAction(){
            },
            AIPlayWatchClose(){},
            
            confirmPlay(){
                if(!this._board) return
                this._board.confirmPlay()
            },
            async gameCount(){
                this.timeStop()
                if(!this.pageLoaded) return
                await this._common.nextTick.call(this)
                this._board.cancelPlay()
                this.localDataClear()
                await this.saveGame()
                if(this.willBack || this.isPlay){
                    if(!this.willBack && this.isPlay) this.gameResultCon({active: true})
                    return
                }
                let res = await this.$refs['result'].open()
                
                if(res != 2) await this.paramsReset()
                if(res == 0) return this.playAgain()
                if(res == 1) return this.returnList()
                if(res == 2) return this.$refs['result'].close()
                
            },
            changeLabel(label){
                if(this._common.isBool(label)){
                    label = label ? 1 : 0
                }else{
                    label = this.boardStoneLabel
                    label = label == 0 ? 1 : 0
                }
                this._board.changeLabel(label)
            },
            async resign(){
                if(this.resignDisabled) return
                let minNum = this.minGameMoveNum
                let subMessage = this.pageText.t13
                let message = this.pageText.t11
                this.timeStop()
                let res = await this.normalConfirmPro({subMessage, message, confirmType: 'c2', defineClass: 'resign'})
                if(!res) return this.timeStartCon()
                this.resignAction()
            },
            resignAction(type){
                type = type || 'user'
                if(this[`${type}Resign`] === undefined) return
                this[`${type}Resign`] = true
            },
            async AIResignControl(){
                let message = this.pageText['AI resign confirm']
                let res = await this._common.confirmPro.open({
                    message,
                    confirmType: 'c10 balance-check',
                    buttons: [
                        {title: '同意认输', label: 0, type: 'confirm'},
                        {title: '继续对弈', label: 1, type: 'cancel'},
                    ]
                })
                res = res || {}
                return res.label == 0 ? this.resignAction('AI') : this.refuseAIResign()
            },
            async refuseAIResign(){
                this.refuseResignMove = this.position
                await this._common.nextTick.call(this)
                this.AIPlay()
            },
            timeInit(){
                this.$refs['leftTime'].reset()
                this.$refs['rightTime'].reset()
            },
            timeStartCon(type){
                this.timeStop()
                if(this.timeDisabled) return
                // let {now} = this
                // let key = now == 1 ? 'black' : 'white'
                // let ele = this.$refs[`${key}MainTime`]
                // if(!ele || !ele.start) return
                // ele.start()
                let {userColor, now} = this
                let key = now == userColor ? 'left' : 'right'
                let ele = this.$refs[`${key}Time`]
                if(!ele || !ele.start) return
                ele.start(type)
            },
            timeStop(){
                if(this.$refs['leftTime']) this.$refs['leftTime'].stop()
                if(this.$refs['rightTime']) this.$refs['rightTime'].stop()
            },
            timeUpdate(val, color){
                if(this[`${color}MainTimeVal`] === undefined){
                    return
                }
                if(!this.pageLoaded){
                    return
                }
                this[`${color}MainTimeVal`] = val
                this.localTimeSave()
            },
            returnList(){
                // this._routeHand.goto({
                //     name: 'game-list',
                //     type: 'replace'
                // })
                this._routeHand.back()
            },
            async paramsReset(){
                // let {boardSize, captureNum, moveNum, userColor, komi, gameType, rule, AILevel, style, apiName: api} = this
                // let params = {
                //     boardSize, captureNum, moveNum, userColor, komi, gameType, rule, AILevel, style, api
                // }
                let params = {id: this.id}
                params = this._common.encodeData(params)
                this.refreshPageAction({params})
                await this._common.nextTick.call(this)
                return true
            },
            async playAgain(){
                // let {boardSize, captureNum, moveNum, komi, gameType, rule, AILevel, style, judgeMove, judgeSplit, apiName: api} = this
                // let params = {
                //     boardSize, captureNum, moveNum, komi, gameType, rule, AILevel, style, judgeMove, judgeSplit, api
                // }
                let params = {id: this.id}
                params = this._common.encodeData(params)
                this.dataInit()
                await this.refreshPageAction({params})
                await this._common.nextTick.call(this)
                this.gameReload()
            },
            dataInit(){
                this.blackMainTimeVal = null
                this.whiteMainTimeVal = null
                this.userResign = null
                this.AIResign = null
                this.saveGameData = {}
                this.refuseResignMove = null
            },
            localDataInitCheck(){
                this.boardLocalInit()
                this.timeLocalInit()
            },
            boardLocalInit(){
                let moves = this.appData.getItem('moves') || null
                try{
                    moves = moves.split(',')
                }catch(e){moves = []}
                if(!this._common.isArray(moves)) moves = []
                if(!moves.length) moves = this.boardInitMoves
                this._board.loadSgf(moves)
            },
            // boardInitMoves(){
            //     let {boardSize} = this
            //     let moves = this._common.getCaptureInitBoard(boardSize)
            //     return moves
            // },
            timeLocalInit(){
                // let blackMainTimeVal = this.appData.getItem('blackMainTimeVal')
                // let whiteMainTimeVal = this.appData.getItem('whiteMainTimeVal')
                // this.timeInit()
                // this.blackMainTimeVal = blackMainTimeVal || null
                // this.whiteMainTimeVal = whiteMainTimeVal || null
                // if(blackMainTimeVal !== undefined) this.$refs['leftTime'].setValue({mainTime: blackMainTimeVal})
                // if(whiteMainTimeVal !== undefined) this.$refs['rightTime'].setValue({mainTime: whiteMainTimeVal})
                this.timeInit()
                let leftTime = this.appData.getItem('leftTime') || {}
                let rightTime = this.appData.getItem('rightTime') || {}
                this.leftTimeData = leftTime
                this.rightTimeData = rightTime

                if(this.$refs['leftTime']){
                    let {mainTime = this.mainTime, countdownTime = this.countdownTime, countdownNum = this.countdownNum} = leftTime
                    this.$refs['leftTime'].setValue({mainTime, countdownTime, countdownNum})
                }

                if(this.$refs['rightTime']){
                    let {mainTime = this.mainTime, countdownTime = this.countdownTime, countdownNum = this.countdownNum} = rightTime
                    this.$refs['rightTime'].setValue({mainTime, countdownTime, countdownNum})
                }
            },
            localBoardSave(){
                let moves = this._board.getSituation()
                this.appData.setItem({moves})
            },
            localTimeSave(){
                // let {blackMainTimeVal, whiteMainTimeVal} = this
                // this.appData.setItem({blackMainTimeVal, whiteMainTimeVal})
                let {leftTimeData: leftTime, rightLeftData: rightTime} = this
                this.appData.setItem({leftTime, rightTime})
            },
            localDataClear(){
                let items = {
                    moves: true,
                    blackMainTimeVal: true,
                    whiteMainTimeVal: true,
                    leftTime: true,
                    rightTime: true,
                }
                this.appData.removeItem(items)
            },
            async saveGame(){
                if(this.saveGameDisabled) return
                let data = this.saveGameParams
                let res = await this.api.saveGame(data)
                if(!res) return
                this.saveGameData = res
            },
            async back(){
                if(this.gameOver){
                    // return this.backToList()
                    this._routeHand.back()
                    return true
                }
                let message = this.pageText.t15
                let subMessage = this.pageText.t14
                let res = await this.normalConfirmPro({subMessage, message, confirmType: 'c6', defineClass: 'back'})
                if(!res) return false
                this.willBack = true
                this.resignAction()
                // this.backToList()
                this._routeHand.back()
                return true

            },
            eventSet(){
                this.eventOff()
                this._common.eventSet('gameBack', e => {
                    this.back()
                })
                // this._routeHand.beforeEachOn('game-action', this.back)
            },
            eventOff(){
                this._common.eventRemove('gameBack')
            },
            backToList(){
                // this._routeHand.goto({
                //     name: 'game-list',
                //     type:  'replace'
                // })
                this._routeHand.back()
            },
            funHand(val){
                val = val || {}
                let {fun} = val
                if(!this[fun]) return
                this[fun](val)
            },
            async passClick(){
                if(this.userPlayDisabled) return
                let res = await this.passConfrim()
                if(!res) return
                this.passAction()
            },
            passAction(){
                this.$refs['board'].pass()
            },
            async passConfrim(){
                let message = this.pageText.t18
                let res = await this.normalConfirmPro({message, confirmType: 'c3'})
                return res
            },
            gameJudgeCheck(){
                // return true
                if(!this.judgeDisabled) return true
                let message = this.judgeDisabled
                this._common.toastOpen({
                    message
                })

                return false
            },
            gameJudge(){
                if(this.judgeActive) return this.gameJudgeOff()
                if(!this.gameJudgeCheck()) return
                this.gameJudgeOn()
            },
            gameJudgeOff(){
                this.judge.active = false
                this.judge.data = {}
                this._board.gameJudgeOffPlay()
            },
            async endGame(){
                let message = this.pageText.t21
                if(!this.gameOver){
                    let res = await this.normalConfirmPro({message, confirmType: 'c3'})
                    if(!res) return
                }
                this.backToList()
            },
            async gameContinue(){
                let over = this.gameOver
                this.gameJudgeOff(over)
                if(over){
                    return this.playAgain()
                }
                this.timeStartCon()
            },
            async gameJudgeOn(judgeData){
                let level = this.AILevel
                let {boardSize, komi} = this
                let moves = this._board.getSituation()
                let res = judgeData || await this.api.judge({level, boardSize, moves, komi})
                if(!res) return
                this.gameJudgeHand(res)
            },
            gameJudgeHand(res){
                this.timeStop()
                res = res || {}
                this.judge.active = true
                this.judge.data = this._common.deepCopy(res)
                let data = res.belong
                let result = this._board.gameJudgeOn(data)
            },
            gameResultCon(val){
                val = val || {}
                let {active} = val
                this.judgeMaskState = active
                
            },
            loadTest(){
                // let moves='40,33,42,41,32,51,50,34,52,43,60,23,22,24,14,15,12,5,13,4,3,31,30,6,44,35,53,17,16,7,25,26,20,61,70,69,62,59,68,58,78,77,76,49,57,67,66,48,47,56,39,46,55,37,65,54,63,45,28,64,73,19,38,27,36,18,10,45,9,27,18,37,46,58,54,59,2'
                let moves = '40,32,41,42,51,43,31,52,60,23,22,14,61,50,49,59,68,67,58,66,57,5,13,77,4,56,47,64,55,65,54,63,46,69,59,78,70,62,79,75,73,25,71,53,76,77,78,74,76,72,-1,77,29,34,48,39,38,24,20,30,19,26,18,7,76,6,-1,77,-1,16,-1,8,21,10,36,9,28,1,12,2,76,3,39,77,11,44,0,10,9,2,1,-1,76,33,3,77,-1,-1,76'
                this._board.loadSgf(moves.split(','))
            },
            async normalConfirmPro(opt){
                let {
                    title, message,
                    confirmText = this.pageText.t16,
                    cancelText = this.pageText.t17,
                    defineClass,
                } = opt || {}
                defineClass = defineClass || ''
                defineClass += ' game'
                let res = await this._common.confirmPro.open({
                    ...opt,
                    message,
                    defineClass,
                    buttons: [
                        {title: confirmText, type: 'confirm', label: 0},
                        {title: cancelText, type: 'cancel', label: 1},
                    ]
                })
                res = res || {}
                return res.label == 0
            },
            async normalConfirm(opt){
                let {
                    message = this.pageText.t21,
                    confirmText = this.pageText.t16,
                    cancelText = this.pageText.t17,
                } = opt || {}
                // if(this.gameOver) return true
                let res = await this._common.confirmPro.open({
                    message,
                    buttons: [
                        {title: confirmText, type: 'confirm', label: 0},
                        {title: cancelText, type: 'cancel', label: 1},
                    ]
                })
                res = res || {}
                return res.label == 0
            },
            async test(){
                await this.$refs['gameColorNoticePro'].open()
            },
            async judgeChcek(){
                let {position, judgeMove, judgeSplit} = this
                await this._common.nextTick.call(this)
                if(!this.judgeChcekFlag) return true
                let res = await this.judgeCheckAction()
                if(!res) return true
                let state = await this.judgeCheckNotice(res)
                if(!state) return true
                this.gameJudgeOn(res)
                return false
            },
            async judgeCheckAction(){
                let {boardSize, komi, AILevel: level} = this
                let moves = this._board.getSituation()
                let res = await this.api.judge({level, boardSize, moves, komi, noError: true, noLoad: true})
                if(!res) return false
                let {winner} = res
                if(winner != 'B' && winner != 'W') return false
                return res
            },
            async judgeCheckNotice(){
                let message = this.pageText['game judge auto check']
                let res = await this._common.confirmPro.open({
                    message,
                    confirmType: 'c10 balance-check',
                    buttons: [
                        {title: '同意数子', label: 0, type: 'confirm'},
                        {title: '继续对弈', label: 1, type: 'cancel'},
                    ]
                })
                res = res || {}
                let {label} = res
                return label == 0
            },
            timeChange(type, val){
                if(!this.pageLoaded) return
                val = val || {}
                let key = `${type}TimeData`
                if(!this[key]) return
                this[key] = {
                    ...this[key],
                    ...val
                }
                this.localTimeSave()
            },
            timeout(type){
                
            }
        },
        created() {
            this.eventSet()
        },
        components: {
            board,
            // gameUserGroup,
            resultToast,
            gameColorNoticePro,
            optionsMask,
            gameTime
        },
        computed: {
            mainTime(){
                let {main_time: mainTime} = this.gameTime
                mainTime = mainTime || 15
                return mainTime * 60
                // return 0.1 * 60
            },
            countdownTime(){
                let {countdownTime} = this.gameTime
                return countdownTime
                // return 10
            },
            countdownNum(){
                let {countdown_num: countdownNum} = this.gameTime
                return countdownNum
                // return 2
            },
            gameTime(){
                let {game_time} = this.AISelected
                game_time = game_time || {}
                let {countdownTime, countdown} = game_time
                if(countdownTime !== undefined || countdown !== undefined){
                    let tem = countdownTime !== undefined ? countdownTime : countdown
                    countdown = tem
                    countdownTime = tem
                }
                return {...game_time, countdown, countdownTime}
            },
            AITime(){
                let {AI_time} = this.AISelected
                return AI_time
            },
            AISelected(){
                let {AIList: list, id: value} = this
                let {data} = this._common.selected({list, value, key: 'id'})
                data = data || {}
                let {description} = data
                if(!this._common.isObject(description)) description = {}
                data = {
                    ...data,
                    ...description
                }
                data.rule = data.rule || data.supportRules
                return data
            },
            judgeChcekFlag(){
                let {position, judgeMove, judgeSplit} = this
                if(!judgeMove || !judgeSplit) return
                let delta = position - judgeMove
                let num = delta % judgeSplit
                if(delta == 0 || (delta % judgeSplit) == 0 || (delta % judgeSplit) == 1) return true
                return false
            },
            id(){
                let {id} = this.routeDataCul
                return id
            },
            judgeMove(){
                let {judgeMove: data} = this.AISelected
                if(data !== undefined) return data
                let {judgeMove} = this.routeDataCul
                return judgeMove
            },
            judgeSplit(){
                let {judgeSplit: data} = this.AISelected
                if(data !== undefined) return data
                let {judgeSplit} = this.routeDataCul
                return judgeSplit
            },
            gift(){
                return this.saveGameData.gift || null
            },
            removeTitle(){
                return this.isPlay ? this.pageText.t23 : this.pageText.t3
            },
            style(){
                let {style: data} = this.AISelected
                if(data !== undefined) return data
                let {style} = this.routeDataCul
                return style || undefined
            },
            continueText(){
                return this.winColor ? '再来一局' : '继续下棋'
            },
            resignDisabled(){
                return this.gameOver
            },
            rule(){
                let {rule: data} = this.AISelected
                if(data !== undefined) return data
                let {rule = 'chinese'} = this.routeDataCul
                // if(!rule){
                //     rule = 'capture'
                // }
                return rule
            },
            funListCul(){
                if(!this.isPlay) return []
                return this.funList
            },
            gameRule(){
                let {gameType} = this
                return this[`${gameType}_gameRule`]
            },
            capture_gameRule(){
                let text = this.pageText
                let {boardSize} = this
                let key = `rule_${boardSize}`
                return `${text.t12}：${text[key]}`
            },
            
            play_gameRule(){
                let text = this.pageText
                let {boardSize} = this
                return text[`komi_${boardSize}`]
            },
            resultText(){
                let data = this.playResultData
                return this._common.parseResult(data)
            },
            
            computerResign(){
                let {AIData: {resign}, refuseResignMove, position} = this
                let data = 0
                if(resign !== undefined && !isNaN(Number(resign))) data = resign
                else{
                    let type = this.gameType
                    data = type == 'capture' ? 0 : 2
                }
                if(data && refuseResignMove !== null && (position - refuseResignMove) < this.PARAMS.resign_split_num) data = 0
                return data
            },
            judgeActive(){
                return this.judge.active
            },
            judgeDisabled(){
                let {boardSize, positionCul} = this
                let list = this._common.gameJudgeMoveNum
                let num = list[boardSize] || 0
                if(positionCul >= num) return false
                let message = this.pageText.judgeDis
                message = this._common.urlParse(message, {num})
                // return this.pageText[`judge_${boardSize}`]
                return message
            },
            saveGameDisabled(){
                return this.positionCul <= this.saveGameMinNum
            },
            saveGameMinNum(){
                let {boardSize} = this
                let list = this.PARAMS.minGameMoveNum
                return list[boardSize] || 0
            },
            saveGameParams(){
                if(!this.boardState) return {}
                let {blackInfo, whiteInfo, boardSize, userWin, resultData, winColor, userTimeout, AITimeout, winNum, gameType, komi, handicap, position, rule, createTime: create_time} = this
                
                let gamename = this.optTitle
                let pb = blackInfo.nickname
                let pw = whiteInfo.nickname
                let move_num = this.positionCul
                let game_result = this._common.getResultKey({win: winColor, timeout: (userTimeout || AITimeout), winNum})
                let game_type = this._common.getGameType(gameType)
                let black_level = blackInfo.level
                let white_level = whiteInfo.level
                let play_time = this._common.getTimeNow()
                let start_move_num = this.boardMinNum
                let sgf = this._board.CreateSgfData({position})
                let options = {
                    gamename, pb, pw, move_num, handicap, komi, game_result, sgf,
                    game_type, black_level, white_level, play_time, start_move_num,
                    board_size: boardSize, rule, create_time
                }
                return options


            },
            handicap(){
                return 0
            },
            komi(){
                let {komi: data} = this.AISelected
                if(data !== undefined) return data
                let {komi} = this.routeDataCul
                return komi || 7.5
            },
            gameType(){
                let {gameType: data} = this.AISelected
                if(data !== undefined) return data
                let {gameType} = this.routeDataCul
                return gameType || 'capture'
            },
            winNum(){
                if(!this.isPlay) return 0
                let {
                    judge: {
                        data: {
                            winner = 'U',
                            delta = 0
                        } = {}
                    } = {}
                } = this
                return winner == 'U' ? 0 : delta
            },
            positionCul(){
                let minNum = this.boardMinNum
                return this.position - minNum
            },
            isPlay(){
                return this.gameType == 'play'
            },
            boardInitMoves(){
                if(this.isPlay) return []
                let {boardSize} = this
                let moves = this._common.getCaptureInitBoard(boardSize)
                return moves || []
            },
            boardMinNum(){
                let {boardState, boardInitMoves} = this
                if(!boardState) return 0
                return boardInitMoves.length
            },
            minGameMoveNum(){
                let list = this.PARAMS.minGameMoveNum
                let {boardSize} = this
                let data = list[boardSize] || 0
                return data
            },
            stoneLabelValue(){
                return this.boardStoneLabel == 1
            },
            userTimeout(){
                // let key = 'black'
                // return this[`${key}MainTimeVal`] !== null && this[`${key}MainTimeVal`] <= 0

                let {mainTime, countdownTime, countdownNum} = this.leftTimeData
                if(mainTime <= 0 && countdownTime <= 0 && countdownNum <= 0) return true
                return false
            },
            AITimeout(){
                // let key = 'white'
                // return this[`${key}MainTimeVal`] !== null && this[`${key}MainTimeVal`] <= 0

                let {mainTime, countdownTime, countdownNum} = this.rightTimeData
                if(mainTime <= 0 && countdownTime <= 0 && countdownNum <= 0) return true
                return false
            },
            timeDisabled(){
                if(!this.pageLoaded || this.gameOver || !this.pageLoaded) return true
                return false
            },
            userInfo(){
                let {nickname, photo, level, usercode} = this.$store.state.app
                return {nickname, photo, level, usercode}
            },
            AIInfo(){
                let photo = 'user_photo/gaotu_tutu.png'
                let level = this.AILevel
                let nickname = this.PARAMS.AIName_gaotu
                return {nickname, photo, level}
            },
            leftInfo(){
                return this.userColor == 1 ? this.blackInfo : this.whiteInfo
            },
            rightInfo(){
                return this.userColor == -1 ? this.blackInfo : this.whiteInfo
            },
            blackInfo(){
                let {userColor, AIColor } = this
                if(userColor == 1) return this.userInfo
                if(AIColor == 1) return this.AIInfo
                if(!userColor) return this.userInfo
                let photo = 'img/m5Light.png', nickname = '', level = 0
                return {photo, nickname, level}
                
            },
            whiteInfo(){
                let {userColor, AIColor } = this
                if(userColor == -1) return this.userInfo
                if(AIColor == -1) return this.AIInfo
                if(!userColor) return this.AIInfo
                let photo = 'img/m5Light.png', nickname = '', level = 0
                return {photo, nickname, level}
            },
            resultData(){
                if(!this.gameOver) return null
                let {gameType: type} = this
                return this[`${type}ResultData`]
            },
            playResultData(){
                let {AIResign, userResign, winColor, AITimeout, userTimeout} = this
                let k1 = '', k2 = ''
                if(AIResign || userResign || AITimeout || userTimeout){
                    k1 = winColor == 'black' ? 'B' : 'W'
                    if(!AITimeout && !userTimeout) k2 = 'R'
                    else k2 = 'T'
                }else{
                    let {active, data} = this.judge
                    data = data || {}
                    let {winner, delta} = data
                    if(!active || winner == 'U') return null
                    k1 = winner
                    k2 = delta
                }
                return `${k1}+${k2}`
            },
            captureResultData(){
                let {userWin, AIWin, isNR, userTimeout, AITimeout} = this
                let result
                if(isNR) result = 'nr'
                else if(userTimeout || AITimeout) result = 'nt'
                else result = 'normal'
                return result
            },
            winColor(){
                if(!this.gameOver) return null
                let {gameType} = this
                return this[`${gameType}_winColor`]
            },
            play_winColor(){
                let {userColor, userWin} = this
                let c = userWin ? userColor : -userColor
                return c == 1 ? 'black' : 'white'
            },
            capture_winColor(){
                if(this.resultData == 'nr') return null
                let {userColor, userWin} = this
                let c = userWin ? userColor : -userColor
                return c == 1 ? 'black' : 'white'
            },
            userWin(){
                if(!this.gameOver) return false
                let {gameType} = this
                let val = this[`${gameType}UserWin`]
                return val
                
            },
            playUserWin(){
                let {AITimeout, AIResign, userColor, judge: {data: {winner} = {}} = {}} = this
                if(AITimeout || AIResign) return true
                userColor = userColor == 1 ? 'B' : 'W'
                return userColor == winner
            },
            captureUserWin(){
                let {userRemove, captureNum, AITimeout} = this
                if(userRemove >= captureNum || AITimeout) return true
                return false
            },
            AIWin(){
                if(!this.gameOver) return false
                let {userResign, AIRemove, captureNum, userTimeout} = this
                if(userResign || AIRemove >= captureNum || userTimeout) return true
                return false
            },
            isNR(){
                if(!this.gameOver) return false
                let {userWin, AIWin, position, moveNum} = this
                if(userWin || AIWin) return false
                return position >= moveNum

            },
            confirmDisabled(){
                if(this.gameOver) return true
                let {gamePlayCoor} = this
                return !gamePlayCoor || !Object.keys(gamePlayCoor).length
            },
            userRemove(){
                let {userColor} = this
                if(!userColor) return 0
                let key = userColor == 1 ? 'black' : 'white'
                return this[`${key}Remove`]
            },
            AIRemove(){
                let {AIColor} = this
                if(!AIColor) return 0
                let key = AIColor == 1 ? 'black' : 'white'
                return this[`${key}Remove`]
            },
            gameOver(){
                let {gameType} = this
                return this[`${gameType}GameOver`]
            },
            playGameOver(){
                let {userTimeout, AITimeout, userResign, AIResign, judge: {data: {winner = 'U'} = {}} = {}} = this
                let f = userResign ||
                AIResign ||
                userTimeout ||
                AITimeout ||
                winner != 'U'
                return f

            },
            captureGameOver(){
                let {positionCul, blackRemove, whiteRemove, captureNum, userTimeout, AITimeout, userResign, moveNum} = this
                return positionCul >= moveNum || 
                blackRemove >= captureNum || 
                whiteRemove >= captureNum || 
                userResign ||
                userTimeout ||
                AITimeout
            },
            AILevel(){
                let {eloScore: data} = this.AISelected
                if(data !== undefined) return data
                let {AILevel} = this.routeDataCul
                AILevel = Number(AILevel)
                return AILevel
            },
            AIData(){
                let {AILevel, boardSize} = this
                let list = this.AIListCul
                let data = {}
                for(let i in list){
                    let {eloScore: level, boardSize: size} = list[i]
                    if(AILevel == level && boardSize == size){
                        let {description} = list[i]
                        data = {...list[i], ...description}
                        break
                    }
                }
                return data
            },
            AIListCul(){
                let {boardSize, AIList} = this
                if(!boardSize) return []
                let data = []
                let types = this.PARAMS.game_level_keys
                for(let i in AIList){
                    let size = AIList[i].boardSize
                    if(size != boardSize) continue
                    let {description} = AIList[i]
                    if(!this._common.isObject(description)) continue
                    let {type} = description
                    if(!types[type]) continue
                    data.push(AIList[i])
                }
                return data
            },
            leftRemove(){
                return this.userColor == 1 ? this.blackRemove : this.whiteRemove
            },
            rightRemove(){
                return this.userColor == 1 ? this.whiteRemove : this.blackRemove
            },
            blackRemove(){
                let {removeStone} = this
                removeStone = removeStone || {}
                let num = removeStone.w || 0
                return num
            },
            whiteRemove(){
                let {removeStone} = this
                removeStone = removeStone || {}
                let num = removeStone.b || 0
                return num
            },
            userPlayDisabled(){
                if(!this.isUserPlay || this.gameOver || this.judgeActive || this.pageDestroy) return true
                return false
            },
            AIPlayDisabled(){
                if(!this.isAIPlay || this.gameOver || this.AILevel === undefined || this.pageDestroy) return true
                return false
            },
            optTitle(){
                let {gameType} = this
                return this[`${gameType}Title`]
            },
            playTitle(){
                let text = this.pageText
                let {boardSize, captureNum} = this
                let t1 = `${boardSize}${text['t1']}`
                let t2 = text.t19
                return `${t1}${t2}`
            },
            captureTitle(){
                let text = this.pageText
                let {boardSize, captureNum} = this
                let t1 = `${boardSize}${text['t1']}`
                let t2 = `${captureNum}${text['t2']}`
                if(this.isPlay){
                    t2 = text['t19']
                }else{
                   t1 +=  text['t1-1']
                }
                return `${t1}${t2}`
            },
            routeDataCul(){
                let {routeData} = this
                let data = this._common.routeDataCul(routeData)
                return data
            },
            boardSize(){
                let {boardSize: data} = this.AISelected
                if(data !== undefined) return data
                let {boardSize} = this.routeDataCul
                return boardSize || 13
            },
            captureNum(){
                let {captureNum: data} = this.AISelected
                if(data !== undefined) return data
                let {captureNum} = this.routeDataCul
                return captureNum || 5
            },
            moveNum(){
                let {moveNum: data} = this.AISelected
                if(data !== undefined) return data
                let {moveNum} = this.routeDataCul
                return moveNum || 60
            },
            color(){
                let {color} = this.routeDataCul
                return color
            },
            userColor(){
                let {userColor} = this.routeDataCul
                return userColor
            },
            AIColor(){
                let {userColor} = this
                if(!userColor) return
                return userColor == 1 ? -1 : 1
            },
            isAIPlay(){
                return this.AIColor == this.now
            },
            isUserPlay(){
                return this.userColor == this.now
            },
            apiName(){
                let {api: data} = this.AISelected
                if(data !== undefined) return data
                let {api} = this.routeDataCul
                return api
            },

        },
        watch: {
            judge: {
                handler(val, oldval){
                    if(this.gameOver) return
                    this.gameResultCon(val)
                },
                deep: true,
                immediate: true
            },
            gameOver: {
                handler(val, oldval){
                    if(!val || val == oldval) return
                    this.gameCount()
                },
                deep: true,
                immediate: true
            },
            isAIPlay: {
                handler(val){
                    if(val) this.AIPlayWatchOpen()
                    else this.AIPlayWatchClose()
                },
                deep: true,
                immediate: true
            },
            position: {
                handler(){
                    this.positionChange()
                },
                immediate: true,
                deep: true
            },
            $route: {
                handler(){
                    this.routeData = this.$route.params.routeData
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {
            // this.$refs['blurOverlay'].open({
            //     getContainer: '.cap-item.options.game-options-box'
            // })
            this._voiceHand.normalPlay('game bcg')
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.pageDestroy = true
            this.localDataClear()
            this.eventOff()
            this._voiceHand.normalStop('game bcg')
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .page-game-capture{
        position: relative;
        .ex-bcg-box{
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100vw;
            height: 100vh;
            .stone-item{
                position: absolute;
            }
            .black{
                width: 94px;
                height: 51px;
                bottom: 0px;
                left: 0px;
                .background-img-max(url('@{assetsUrlV2_0}/exercise/c1.png'));
            }
            .white{
                width: 93px;
                height: 58px;
                top: 0px;
                right: 0px;
                .background-img-max(url('@{assetsUrlV2_0}/exercise/c2.png'));
            }
        }
        .fun-test{
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 305;
        }
        .capture-body{
            display: flex;
            align-items: center;
            .cap-item.board{
                .board-body{
                    position: relative;
                    width: 100%;
                    height: 100%;
                }
            }
            .cap-item.options{
                padding: 0px 10px;
                position: relative;
                // overflow: hidden;
                .ex-com-result-mask{
                    z-index: 2;
                }
            }
            .cap-item.options.filter-blur{

            }
            .options-body{
                position: relative;
                height: 100%;
				
                .opt-item.title{
                    // width: 108px;
                    height: 15px;
                    // line-height: 32px;
                    // background: linear-gradient(315deg, #FFCFB0 0%, #FF664A 100%);
                    margin: 0px auto;
                    // border-radius: 0px 0px 12px 12px;
                    color: #465D71;
                    position: absolute;
                    top: 6px;
                    left: 0px;
                    width: 100%;
                }
                .opt-item.rule{
                    padding: 32px 0px 4px;
                    margin: 0px 6px;
                    background: url('@{assetsUrlV2_0}/game/g21.png') no-repeat left bottom;
                    background-size: 100% 2px;

            // .background-img-max(url('@{assetsUrlV2_0}/global/c1_3.png'));

                    span[class^='text']{
                        height: 12px;
                    }
                }
                .opt-item.remove-box{
                    width: 164px;
                    height: 20px;
                    margin: 8px auto 0px;
                    position: relative;
                    .background-img-max(url('@{assetsUrlV2_0}/game/g22.png'));
                    .re-item{
                        width: calc((100% - 62px) / 2);
                        height: 100%;
                        color: #58423C;
                    }
                    .re-item.title{
                        width: 62px;
                        color: #875C1D;
                        margin: 0px auto;
                    }
                }
                .opt-item.g-info{
                    margin-top: -2px;
                    width: 100%;
                    height: 115px;
                    display: flex;
                    position: relative;
                    .info-item{
                        width: 100%;
                        height: 100%;
                    }
                    .info-item.bcg{
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        .bcg-i{
                            position: absolute;
                            top: 0px;
                            height: 115px;
                        }
                        .a{
                            z-index: 1;
                            left: 0px;
                            width: 105px;
                            .background-img-max(url('@{assetsUrlV2_0}/game/g23.png'));
                        }
                        .b{
                            z-index: 0;
                            right: 0px;
                            width: 124px;
                            .background-img-max(url('@{assetsUrlV2_0}/game/g24.png'));
                        }
                    }
                    .info-item.content{
                        z-index: 2;
                        .con-item{
                            width: 50%;
                            height: 100%;
                            position: absolute;
                            overflow: hidden;
                            .pet-body-content{
                                width: auto;
                            }
                            .com-global-avatar-box{
                                height: 100%;
                            }
                            .user-avatar, .com-global-pet-animation, .pet-item.b, .pet-body-content, .com-user-pet{
                                height: 100%;
                            }
                            .user-avatar{
                                display: flex;
                                align-items: flex-end;
                            }
                            .com-global-pet-box{
                                // height: calc(100% - 18px);
                                height: 100%;
                                margin: 0px;
                                display: flex;
                                align-items: flex-start;
                                // padding-bottom: 5px;
                            }
                            .com-user-pet{
                                height: calc(100% - 18px);
                                .com-global-pet-animation{
                                    img{
                                        width: auto;
                                        height: 26px;
                                    }
                                } 
                            } 
                            
                            .com-global-AI, .com-global-user-avatar{
                                width: auto;
                                margin: 4px auto 0px;
                                height: calc(100% - 18px);
                                img{
                                    height: 100%;
                                    width: auto;
                                }
                            }
                            .com-global-user-avatar{
                                img{
                                    width: auto;
                                    height: 87px;
                                    // height: 100%;
                                    // width: auto;
                                }
                            }
                            .stone{
                                width: 9px;
                                height: 9px;
                                position: absolute;
                                top: 2px;
                            }
                            // .stone.black{
                            //     .background-img-max(url('@{assetsUrlV2_0}/game/g19.png'));
                            // }
                            // .stone.white{
                            //     .background-img-max(url('@{assetsUrlV2_0}/game/g18.png'));
                            // }

                            .stone.black{
                                .background-img-max(url('@{assetsUrlV2_0}/game/g19.png'));
                            }
                            .stone.black.active{
                                .background-img-max(url('@{assetsUrlV2_0}/game/g19_active.gif'));
                            }
                            .stone.white{
                                .background-img-max(url('@{assetsUrlV2_0}/game/g18.png'));
                            }
                            .stone.white.active{
                                .background-img-max(url('@{assetsUrlV2_0}/game/g18_active.gif'));
                            }
                            .name-board{
                                position: absolute;
                                bottom: 0px;
                                width: 72px;
                                height: 14px;
                                color: #875C1D;
                            }
                        }
                        .bcg-item{
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 100%;
                            height: 100%;
                            z-index: -1;
                        }
                        .left{
                            left: 0px;
                            .left-bcg{
                                .background-img-max(url('@{assetsUrlV2_0}/game/g24.png'));
                                transform: rotateZ(180deg);
                            }
                            .stone{
                                left: 2px;
                            }
                            .name-board{
                                .background-img-max(url('@{assetsUrlV2_0}/game/g25.png'));
                                left: 0px;
                            }
                        }
                        .right{
                            right: 0px;
                            .right-bcg{
                                .background-img-max(url('@{assetsUrlV2_0}/game/g23.png'));
                                transform: rotateZ(180deg);
                            }
                            .stone{
                                right: 2px;
                            }
                            .name-board{
                                .background-img-max(url('@{assetsUrlV2_0}/game/g26.png'));
                                right: 0px;
                            }
                        }
                    }
                    // overflow: hidden;
                    // @c-size: 57px;
                    // position: relative;
                    // border-radius: 8px;
                }
                .opt-item.time-box{
                    margin-top: 3px;
                    @mid-size: 53px;
                    overflow: hidden;
                    .time-item{
                        // width: calc(50% - @mid-size / 2);
                        width: 50%;
                        text-align: center;
                        justify-content: center;
                        .time-box{
                            display: flex;
                            justify-content: center;
                            height: 12px;
                            align-items: center;
                        }
                    }
                    .time-item.a{
                        float: left;
                    }
                    .time-item.b{
                        float: right;
                    }
                }
                .opt-item.game-fun{
                    // margin-top: 24px;
                    // min-height: 42px;
                    // height: calc(@board-size - 32px - 28px - 68px - 16px - 70px);
                    display: flex;
                    justify-content: center;
                    // align-items: center;
                    // align-content: center;
                    flex-wrap: wrap;
                    margin-top: 11px;
                    .fun-item.normal{
                        width: 82px;
                        height: 28px;
                        .background-img-max(url('@{assetsUrlV2_0}/game/g27.png'));
                    }
                    .fun-item.l{
                        margin-right: 24px;
                    }
                    .fun-item.r{
                        margin-left: 24px;
                    }
                    .fun-item.btm{
                        margin-top: 7px;
                    }
                    .fun-item.b{
                        // background: url('@{assetsUrl}/img/game/g_17.png');
                        // background-size: 100% 100%;
                    }
                    .normal-btn-icon.resign{
                        width: 15px;
                        height: 15px;
                        margin-right: 14px;
                        .background-img-max(url('@{assetsUrlV2_0}/game/g28.png'));
                    }
                    .normal-btn-icon.judge{
                        width: 16px;
                        height: 14px;
                        margin-right: 14px;
                        .background-img-max(url('@{assetsUrlV2_0}/game/g29.png'));
                    }
                    .normal-btn-icon.pass{
                        width: 15px;
                        height: 16px;
                        margin-right: 7px;
                        .background-img-max(url('@{assetsUrlV2_0}/game/g30.png'));
                    }
                    .normal-btn-text{
                        color: #FF7155;
                    }
                }
                .opt-item.game-fun-foot{
                    position: absolute;
                    bottom: 15px;
                    .foot-fun{
                        width: 100px;
                        height: 40px;
                        text-align: center;
                        background: linear-gradient(155deg, #FF985C 0%, #FF8254 21%, #FF6932 100%);
                        box-shadow: 0px 0px 2px 0px #FF3700;
                        border-radius: 8px;
                        .foot-fun-body{
                            width: 84px;
                            height: 25px;
                            background: linear-gradient(357deg, #FF8254 0%, #FF5B1E 100%);
                            border-radius: 6px;
                            > span[class^='text']{
                                line-height: normal;
                            }
                        }
                    }
                    .foot-fun.disabled{
                        background: linear-gradient(155deg, #D9D9D9 0%, #F1F1F1 21%, #DBDBDB 100%);
                        box-shadow: 0px -2px 4px 0px #CDCDCD;
                        .foot-fun-body{
                            background: linear-gradient(357deg, #EFEFEF 0%, #D4D4D4 100%);
                            color: #B2B2B2;
                        }
                        
                    }
                    
                }
                













                .opt-item.c-fun{
                    text-align: center;
                    margin: 20px 0px;
                }
                .opt-item.d-fun{
                    text-align: center;
                }
                .opt-item.info{
                    .info-body{
                        display: flex;
                        align-items: center;
                        .info-item{
                            width: 100%;
                            .timer-box{
                                margin-top: 5px;
                            }
                        }
                        .info-item.b{
                            display: flex;
                            .b-item-i.b{
                                margin: 0px 3px;
                            }
                        }
                    }
                    .info-foot{
                        border-top: 1px solid #333;
                        margin-top: 10px;
                        padding: 10px 0px;
                    }
                } 
            }
        }
        .van-dialog{
            width: auto;
            height: auto;
            // border-radius: 16px;
        }
        .cap-select-color{
            // height: 192px;
            // width: 303px;
            // padding: 19px 0px 0px;
            .color-item::before{
                content: '';
                position: absolute;
                top: -4px;
                left: -4px;
                width: 128px;
                height: 41px;
                z-index: -1;
                .background-img-max(url('@{assetsUrlV2_0}/game/g20-shadow.png'));
            }
            .color-item{
                position: relative;
                width: 120px;
                height: 32px;
                // border: 1px solid #D2D2D2;
                // border-radius: 8px;
                .background-img-max(url('@{assetsUrlV2_0}/game/g20.png'));
                margin: 0px auto;
                display: flex;
                justify-content: center;
                align-items: center;
                .color-icon{
                    width: 17px;
                    height: auto;
                    margin-right: 5px;
                }
            }
            .color-item+ .color-item{
                margin-top: 8px;
            }
        }

        .game-judge-result{
            // height: @designHeight;
            // margin-top: -@app-padding-head;
            .re-body{
                height: 100%;
                width: 100%;
            }
            .re-b-item.b{
                position: absolute;
                top: 170px;
                left: 0;
                right: 0;
                margin: auto;
                // margin-top: 53px;
                .com-dia-button+ .com-dia-button{
                    margin-top: 16px;
                }
                .b-item+ .b-item{
                    margin-top: 18px;
                }
                .b-item.a{
                    .com-confirm-button{
                        width: 136px;
                        height: 44px;
                    }
                }
                .b-item{
                    .com-confirm-button{
                        width: 131px;
                        height: 38px;
                    }
                }
            } 
            .re-b-item.a{
                position: absolute;
                top: 87px;
                left: 0;
                right: 0;
                margin: 0 auto;
                text-align: center;
                .com-stone{
                    width: 32px;
                    height: 32px;
                }
                .re-b-text{
                    width: 100%;
                }
                .re-b-text.b{
                    // margin-top: 7px;
                }
                .result-shadow{
                    // .background-img-max(url('@{assetsUrlV2_0}/game/g39.png'));
                    background-color: rgba(243, 172, 69, 0.5);
                    box-shadow: 0 0 1px 0.5px rgba(243, 172, 69, 0.5);
                    width: auto;
                    display: inline-block;
                    margin-top: 7px;
                    padding: 4px 5px;
                    height:34px;
                    border-radius: 17px;
                    .shadow-two{
                        background-color: rgba(243, 172, 69, 1);
                        box-shadow: 0 0 1px 1px rgba(243, 172, 69, 1);
                        width: auto;
                        display: inline-block;
                        height:26px;
                        line-height: 26px;
                        padding: 0 13px;
                        border-radius: 13px;
                    }
                }
            } 
                    
        }
        .test-vay{
            position: fixed;
            width: 100%;
            height: 190px;
            top: 93px;
            left: 0px;

        }

        .game-play-gift{
            position: absolute;
            width: 100%;
            // height: 53px;
            position: absolute;
            top: 49px;
            .gift-body{
                position: relative;
                width: 85px;
                height: 29px;
                padding-right: 13px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .background-img-max(url('@{assetsUrlV2_0}/exercise/c16.png'));
                .icon{
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    width: 37px;
                    height: 37px;
                    .background-img-max(url('@{assetsUrlV2_0}/global/balance-icon.png'));
                }
                .text{
                }
            }
        }
    }
    .van-dialog.game{
        min-width: 232px;
        min-height: 114px;
        .gotu-confirm-pro{
            padding-top: 39px;
        }
        .pro-message{
            font-size: 12px;

        }
        .com-global-sl-button{
            width: 59px;
            height: 21px;
            .sl-button-body{
                span{
                    font-size: 12px;
                    height: 11px;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
    .van-dialog.game.resign{
        .gotu-confirm-pro{
            padding-top: 37px;
        }
    }
    .van-dialog.game.back{
        .gotu-confirm-pro{
            padding-top: 34px;
        }
    }
</style>